export const DEFAULT_COLORS = {
  // swatches
  neutral: {
    white: '#fff',
    grayLight30: '#f8fafc',
    grayLight3: '#f8fafc', // deprecated
    grayLight20: '#edf0f3',
    grayLight2: '#edf0f3', // deprecated
    grayLight10: '#d5d5d5',
    grayLight1: '#d5d5d5', // deprecated
    grayLight05: '#bebebe',
    gray: '#8a8a8a',
    grayDark05: '#BEBEBE',
    grayDark10: '#696969',
    grayDark1: '#696969', // deprecated
    grayDark20: '#4a4a4a',
    grayDark2: '#4a4a4a', // deprecated
    grayDark30: '#2c2c2c',
    grayDark3: '#2c2c2c', // deprecated
    black: '#000',
  },
  blue: {
    blueLight30: '#7896b5',
    blueLight20: '#1570bc',
    blueLight2: '#1570bc', // deprecated
    blueLight10: '#005b9c',
    blueLight1: '#005b9c', // deprecated
    blue: '#0e497b',
    blueDark10: '#092e4e',
    blueDark1: '#092e4e', // deprecated
    blueDark20: '#041320',
    blueDark2: '#041320', // deprecated
  },
  gold: {
    goldLight30: '#faecce',
    goldLight3: '#faecce', // deprecated
    goldLight20: '#f9d89e',
    goldLight2: '#f9d89e', // deprecated
    goldLight10: '#ffc74c',
    goldLight1: '#ffc74c', // deprecated
    gold: '#ffb81c',
    goldDark10: '#e79900',
    goldDark1: '#e79900', // deprecated
    goldDark20: '#d18700',
    goldDark2: '#d18700', // deprecated
  },
  sky: {
    skyLight10: '#c2eaf9',
    skyLight1: '#c2eaf9', // deprecated
    skyLight05: '#99d8ee',
    sky: '#83d4f1',
    skyDark10: '#76c2ef',
    skyDark1: '#76c2ef', // deprecated
  },
  teal: {
    tealLight20: '#ccefee',
    tealLight2: '#ccefee', // deprecated
    tealLight10: '#76cdc8',
    tealLight1: '#76cdc8', // deprecated
    teal: '#009e92',
    tealDark10: '#24a097',
    tealDark1: '#24a097', // deprecated
  },
  red: {
    redLight20: '#ff979e',
    redLight2: '#ff979e', // deprecated
    redLight10: '#fc5742',
    redLight1: '#fc5742', // deprecated
    red: '#f52a01',
    redDark10: '#c12101',
    redDark1: '#c12101', // deprecated
    redDark20: '#8e1801',
    redDark2: '#8e1801', // deprecated
  },
  green: {
    greenLight20: '#41e88a',
    greenLight2: '#41e88a', // deprecated
    greenLight10: '#00d15b',
    greenLight1: '#00d15b', // deprecated
    green: '#00a046',
    greenDark10: '#006b2f',
    greenDark1: '#006b2f', // deprecated
    greenDark20: '#003819',
    greenDark2: '#003819', // deprecated
  },
  brand: {
    nm: {
      primary: { blue: '#0e497b' },
      secondary: {
        gold: '#ffb81c',
        sky: '#83d4f1', // colors.sky.sky
        teal: '#009e92',
        orange: '#f36f35',
      },
    },
    pcg: {
      // TODO: build out all possible usages (i.e. forms, etc.)?
      gold1: '#e1c88f',
      gold2: '#f2dfac',
      lightBlue1: '#105fcc', // approved active link color
      darkBlue1: '#0f1117',
      darkBlue2: '#0f2160',
      darkBlue3: '#00487c',
      darkBlue4: '#2f3d66', // < hex color was not chosen by design. May change.
      darkBlue5: '#26345f',
      gray: '#949494', // used in progress bar background-color
    },
  },
  // usage
  background: {
    lightTheme: {
      heroLazyLoad: '#edf0f3', // colors.neutral.grayLight20
      lightA: '#fff', // colors.neutral.white
      lightB: '#f8fafc', // colors.neutral.grayLight30
      lightC: '#edf0f3', // colors.neutral.grayLight20
      lightSky: '#76c2ef', // colors.sky.skyDark10
      texture: { blue: '#0e497b' }, // colors.blue.blue
    },
    darkTheme: {
      darkDefault: '#0e497b', // colors.blue.blue
      heroLazyLoad: '#4a4a4a', // colors.neutral.grayDark20
      texture: { sky: '#83d4f1' }, // colors.sky.sky
    },
  },
  text: {
    lightTheme: {
      default: '#2c2c2c', // colors.neutral.grayDark30
      defaultHeading: '#4a4a4a', // colors.neutral.grayDark20
      disabled: '#696969', // colors.neutral.grayDark10
      error: '#c12101', // colors.red.redDark10
      success: '#00a046', // colors.green.green
    },
    darkTheme: {
      default: '#fff', // colors.neutral.white
      disabled: '#d5d5d5', // colors.neutral.grayLight10
      error: '#ff979e', // colors.red.redLight20
      success: '#00d15b', // colors.green.greenLight10
    },
  },
  action: {
    lightTheme: {
      ctaTextDefault: '#fff', // colors.neutral.white
      ctaTextDisabled: '#696969', // colors.neutral.grayDark10
      ctaBgDefault: '#1570bc', // colors.blue.blueLight20
      ctaBgHover: '#005b9c', // colors.blue.blueLight10
      ctaBgActive: '#0e497b', // colors.blue.blue
      ctaBgDisabled: '#d5d5d5', // colors.neutral.grayLight10

      ctaBgSecondary: 'transparent', //
      ctaBgSecondaryHover: 'rgba(0, 91, 156, 0.05)', // colors.blue.blueLight10 5% opacity
      ctaBgSecondaryActive: 'rgba(14, 73, 123, 0.1)', // colors.blue.blue 10% opacity

      ctaBgTertiary: 'transparent', //

      linkDefault: '#1570bc', // colors.blue.blueLight20
      linkVisited: '#1570bc', // colors.blue.blueLight20
      linkHover: '#005b9c', // colors.blue.blueLight10
      linkActive: '#1570bc', // colors.blue.blueLight20

      linkSecondaryDefault: '#4a4a4a', // colors.neutral.grayDark20
      linkSecondaryVisited: '#4a4a4a', // colors.neutral.grayDark20
      linkSecondaryHover: '#000', // colors.neutral.black
      linkSecondaryActive: '#4a4a4a', // colors.neutral.grayDark20

      utilityItemDefault: '#4a4a4a', // colors.neutral.grayDark20
      utilityItemHover: '#1570bc', // colors.blue.blueLight20
      utilityItemActive: '#000', // colors.neutral.black
    },
    darkTheme: {
      ctaTextDefault: '#000', // colors.neutral.black
      ctaTextDisabled: '#696969', // colors.neutral.grayDark10
      ctaTextSecondary: '#fff', // colors.neutral.white
      ctaTextTertiary: '#fff', // colors.neutral.white
      ctaTextTertiaryDisabled: '#d5d5d5', // colors.neutral.grayLight10
      ctaBgDefault: '#ffb81c', // colors.gold.gold
      ctaBgHover: '#e79900', // colors.gold.goldDark10
      ctaBgActive: '#d18700', // colors.gold.goldDark20
      ctaBgDisabled: '#d5d5d5', // colors.neutral.grayLight10

      ctaBgSecondary: 'transparent', //
      ctaBgSecondaryHover: 'rgba(255, 255, 255, 0.11)', // colors.neutral.white 11% opacity
      ctaBgSecondaryActive: 'rgba(255, 255, 255, 0.22)', // colors.neutral.white 22% opacity

      ctaBgTertiary: 'transparent', //

      linkDefault: '#83d4f1', // colors.sky.sky
      linkVisited: '#83d4f1', // colors.sky.sky
      linkHover: '#fff', // colors.neutral.white
      linkActive: '#83d4f1', // colors.sky.sky

      linkSecondaryDefault: '#fff', // colors.neutral.white
      linkSecondaryVisited: '#fff', // colors.neutral.white
      linkSecondaryHover: '#fff', // colors.neutral.white
      linkSecondaryActive: '#fff', // colors.neutral.white

      utilityItemDefault: '#fff', // colors.neutral.white
      utilityItemHover: '#83d4f1', // colors.sky.sky
      utilityItemActive: '#fff', // colors.neutral.white
    },
  },
  forms: {
    // TODO: build out all form options?
    lightTheme: {
      inputFieldText: '#4a4a4a', // colors.neutral.grayDark20
      inputFieldBg: 'transparent',
      inputFieldSelectBg: '#f8fafc', // colors.neutral.grayLight30
      inputFieldActive: '#1570bc', // colors.blue.blueLight20
      inputFieldError: '#c12101', // colors.red.redDark10
    },
    darkTheme: {
      inputFieldText: '#fff', // colors.neutral.white
      inputFieldBg: 'rgba(255, 255, 255, 0.08)', // colors.neutral.white 8% opacity
      inputFieldError: '#ff979e', // colors.red.redLight20
    },
  },
  stroke: {
    lightTheme: {
      print: '#000', // colors.neutral.black
      tableBorder: '#bebebe', // colors.neutral.grayLight05
    },
    darkTheme: { tableBorder: '#fff' }, // colors.neutral.white
  },
};

export default DEFAULT_COLORS;

// Legacy colors here:
export const defaultColorsBak = {
  primary: { blue: '#0e497b' },
  accent: {
    blue: '#092d4d',
    lightBlue: '#83d3ee',
    lightBlue2: '#83d4f1', // TODO: this is barely a difference between "LightBlue 1", let's look at these closer before implementing
    lightBlue3: '#567fa2',
  }, // example: This is the font color for the values section // TODO: confirm these values after getting Sketch file
  accent2: { lightBlue: '#76c2ef' },
  hero: {
    background: '#667785', // TODO: rename/organize these once comp is finalized.
    textStroke: '#2e353a', // TODO: rename/organize these once comp is finalized.
  },
  section: { backgroundBlue: '#0e497b' },
  neutral: {
    white: '#fff',
    gray99: '#f7fafc', // GrayLight30 will be #f8fafc in new template version, #f7fafc will be decommissioned
    gray95: '#edf0f3',
    gray87: '#dfdfdf', // faq item background
    gray80: '#ccc',
    gray59: '#979797', // TODO: ECC: remove this gray and migrate to gray58
    gray58: '#949494', // gray 58% white - will be renaming these in the future (3:1 color contrast on white)
    gray54: '#8a8a8a',
    gray41: '#696969', // gray 41% white - will be renaming these in the future (4.6:1 color contrast on white)
    gray29: '#4a4a4a',
    gray23: '#3a3a3a',
    black: '#000',
  },
  cta: {
    blue: '#1570bc',
    blueHover: '#0e497b',
    blueActive: '#105fcc',
    yellow: '#ffb81c',
    yellowHover: '#cc8b00',
    yellowActive: '#ffb81c',
  },
  system: {
    cautionWarning: '#ffcc33',
    darkBlue: '#004ab3',
    negativeError: '#ff5242',
    darkRed: '#c12101',
    lightRed: '#F99B9A',
    positiveSuccess: '#00a84f',
    darkGreen: '#00803c',
  },
  pcg: {
    gold1: '#e1c88f',
    gold2: '#f2dfac',
    darkBlue1: '#0f1117',
    darkBlue2: '#0f2160',
    darkBlue3: '#00487c',
    darkBlue4: '#2f3d66', // < hex color was not chosen by design. May change.
    darkBlue5: '#26345f',
  },

  modal: { stroke: '#2071AA' }, // TODO: also duplicate in Sass files // TODO: confirm do we REALLY need to add another color so close to our other cta blu(s)? will confirm with creative.

  // Other
  blueLazyloadBg: '#667785',
  inputLeadVariantBG: '#205785',
  inputLeadVariantBGHover: '#285c89',

  // TODO: build in shade and highlight functions
};

/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// local components
import { Row, Col } from '../../foundations/Grid';
import AssistiveText from '../../components/AssistiveText';
import BreadcrumbsNavigation from './components/BreadcrumbsNavigation';
import Button from '../../components/Button';
import InformationalBanner from './InformationalBanner'; // TODO Refactor to work with new changes
import Link from '../../components/Link';
import Search from './components/Search';
import SkipNav from './components/SkipNav';
import UserLoginButton from './components/UserLoginButton';
// icons
import BeachChairWithBorderIcon from '../../foundations/Icon/icons/illustrative/lightTheme/BeachChairWithBorder';
import CircleWithPerson from '../../foundations/Icon/icons/utility/CircleWithPerson';
import MagnifyingGlass from '../../foundations/Icon/icons/utility/MagnifyingGlass';
import MoneyParachuteWithBorderIcon from '../../foundations/Icon/icons/illustrative/lightTheme/MoneyParachuteWithBorder';
import PaperIcon from '../../foundations/Icon/icons/utility/PaperFoldedCorner';
import ShieldWithHeartBorderIcon from '../../foundations/Icon/icons/illustrative/lightTheme/ShieldWithHeartBorder';
// helpers
import { navScript } from '../../../assets/js/helpers/nav.header';
// styles
import {
  ButtonNavLinkUtilitySearchStyled,
  ButtonNavToggleStyled,
  ColNavContainerStyled,
  ColNavUtilityContainerStyled,
  ColNavUtilityFwmContainerStyled,
  ContainerMobileNavStyled,
  DivMobileNavOverlayStyled,
  FwmNavItem,
  FwmNavItemContainer,
  FwmNavLink,
  HeaderStyled,
  LiLoginButtonContainer,
  LiNavLinkSearchContainerStyled,
  NavPrimaryStyled,
  NavUtilityStyled,
  PrimaryHeaderContainerStyled,
} from './styles';

const FAFA_LINK_BUTTON_DEFAULT_PROPS = {
  role: 'menuitem',
  itemProp: 'url',
  className: 'nmx-nav-link nmx-nav-link-utility',
  linkUrl: '/find-a-financial-advisor/',
  id: 'nmx-nav-link-utility-fafa',
};

const CLAIMS_LINK_BUTTON_DEFAULT_PROPS = {
  role: 'menuitem',
  itemProp: 'url',
  className: 'nmx-nav-link nmx-nav-link-utility',
  linkUrl: '/claims/',
  id: 'nmx-nav-link-utility-claims',
};

const CLAIMS_LINK_DEFAULT_PROPS = {
  // TODO: duplicated because one case uses <a> and the other uses <Link>
  role: 'menuitem',
  itemProp: 'url',
  className: 'nmx-nav-link nmx-nav-link-utility',
  href: '/claims/',
  id: 'nmx-nav-link-utility-claims',
};

export const HeaderComponent = React.memo(
  ({
    breadcrumbsNavigationConfig,
    config,
    dynamicConfig,
    headerIsOverlayed,
    headerLogoEyebrow,
    headerLogoEyebrowLarge,
    headerWithoutBorder,
    hideHeader,
    showInformationalBanner,
    skipNavAside,
    skipNavFooter,
    staticInformationalBannerConfig,
    useFwmNav,
  }) => {
    const { basePath, nmxTemplateVersion } = config;
    const theme = useContext(ThemeContext);
    const largeDown = theme.largeDown();
    const smallDown = theme.smallDown();
    const [isMobile, setIsMobile] = useState(largeDown);
    const isSticky = true;

    useEffect(() => {
      // once component is mounted, we can get the nav js on the page
      navScript(theme.pageLoadWindowWidth, theme.pageLoadWindowHeight); // TODO: pass in config.appName for storage listener
      setTimeout(() => {
        // remove preload class so animations now happen on mobile nav toggle
        // annimation was triggering on page load, causing CLS score to jump
        // 150 millis to match animation time
        document.body.classList.remove('preload');
      }, 155);
    }, []);

    useEffect(() => {
      if (largeDown !== isMobile) {
        setIsMobile(largeDown);
      }
    }, [largeDown]);

    const headerStates = {
      isHidden: hideHeader,
      isOverlayed: headerIsOverlayed,
      isLogoEyebrow: hideHeader && headerLogoEyebrow,
      isLogoEyebrowLarge:
        hideHeader && headerLogoEyebrow && headerLogoEyebrowLarge,
      isWithoutBorder: headerWithoutBorder,
    };

    return (
      <>
        <HeaderStyled
          hasBreadcrumbsNavigation={breadcrumbsNavigationConfig}
          headerStates={headerStates}
          nmxTemplateVersion={nmxTemplateVersion}
          basePath={basePath}
          hasFwmNav={useFwmNav}
          id='nmx-header'
          className={classNames(
            'nmx-header',
            'nmx-base',
            { 'is-logo-only': headerStates.isHidden },
            { 'is-overlayed': headerStates.isOverlayed },
            { 'has-logo-eyebrow': headerStates.isLogoEyebrow },
            { 'has-logo-eyebrow-large': headerStates.isLogoEyebrowLarge },
            { 'without-border': headerStates.isWithoutBorder },
          )}
          showInformationalBanner={showInformationalBanner}>
          <PrimaryHeaderContainerStyled isSticky={isSticky}>
            <SkipNav
              skipNavAside={skipNavAside}
              skipNavFooter={skipNavFooter}
            />
            {showInformationalBanner && (!dynamicConfig || (dynamicConfig && dynamicConfig.banner && dynamicConfig.banner.isActive)) && (
              <div className='nmx-informational-banner'>
                <InformationalBanner
                  data-banner-id='nmx-informational-banner'
                  data-app-name={config.appName}
                  data-dynamic-config-base-url={config.dynamicConfigBaseUrl}
                  data-static-config={(dynamicConfig && dynamicConfig.banner) ? { informationalBanner: dynamicConfig.banner } : null
                  }
                  {...staticInformationalBannerConfig}
                />
              </div>
            )}
            {!hideHeader && (
              <div className='nmx-header-mobile-container'>
                {/* TODO: rename aria-label value to open/close */}
                <ButtonNavToggleStyled
                  id='nav-toggle'
                  className='nmx-button'
                  aria-label='Toggle Mobile Menu'>
                  <span className='nav-toggle-bar' />
                </ButtonNavToggleStyled>
                <DivMobileNavOverlayStyled className='nmx-mobile-nav-overlay' />
              </div>
            )}
            <ContainerMobileNavStyled
              className='nmx-container-inner'
              id='nmx-mobile-nav-container'
              isSticky={isSticky}
              hideHeader={hideHeader}>
              <Row className='nmx-row'>
                <ColNavContainerStyled
                  className='nmx-col nmx-nav-primary-container'
                  hideHeader={hideHeader}>
                  <h6
                    className='nmx-header-logo-container'
                    id='header-logo-heading'>
                    <a
                      className='nmx-header-logo'
                      href='/'
                      id='header-logo'>
                      Northwestern Mutual
                      {/* TODO: clean up SVG markup (change 'NW' to 'nm' clean up IDs, descriptions, titles, etc.) */}
                    </a>
                    <meta
                      itemProp='image'
                      content={`${basePath}/template/assets/${nmxTemplateVersion}/images/logos/logo-horizontal-navy.svg`}
                    />
                    <img
                      src={`${basePath}/template/assets/${nmxTemplateVersion}/images/logos/logo-horizontal-navy.svg`}
                      className='nmx-print'
                      alt='Northwestern Mutual'
                    />
                  </h6>
                  {/* remove non-applied DOM elements if logo-only header */}
                  {!hideHeader && (
                    <NavPrimaryStyled
                      aria-label='Primary Navigation'
                      basePath={basePath}
                      className='nmx-nav-primary'
                      id='nmx-nav-primary'
                      itemScope
                      itemType='https://schema.org/SiteNavigationElement'
                      nmxTemplateVersion={nmxTemplateVersion}
                    >
                      <AssistiveText component='h6'>
                        Primary Navigation
                      </AssistiveText>
                      <ul
                        role='menubar'
                        className='reduced'>
                        <li
                          role='none'
                          itemProp='name'
                          className='nmx-nav-link-container nmx-nav-link-primary-container nmx-nav-link-mobile-primary-home-container'>
                          <a
                            role='menuitem'
                            itemProp='url'
                            tabIndex='-1'
                            className='nmx-nav-link nmx-nav-link-mobile-primary-home'
                            href={`${basePath}/`}>
                            Home
                          </a>
                        </li>
                        <li
                          role='none'
                          className='nmx-nav-link-container nmx-nav-link-primary-container'>
                          <a
                            role='menuitem'
                            aria-haspopup='true'
                            className='nmx-nav-link nmx-nav-link-primary'
                            href='/who-we-are/'
                            id='nmx-nav-link-primary-who-we-are'
                            data-subnav-active='false'
                            aria-expanded='false'>
                            About Us
                          </a>
                          <ul
                            role='menu'
                            className='reduced nmx-subnav nmx-subnav-who-we-are'
                            aria-label='About Us Submenu'>
                            <li
                              role='none'
                              className='nmx-nav-link-container nmx-nav-link-mobile-back-container'>
                              <Button
                                id='button-mobile-sub-nav-back-to-main-menu'
                                role='menuitem'
                                variant='tertiary'
                                tabIndex='-1'
                                className='nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub--first nmx-nav-link-mobile-back'>
                                Back to main menu
                                <span className='nmx-nav-mobile-subnav-bg' />
                              </Button>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-mobile-subnav-overview'
                                href='/who-we-are/'>
                                About Us Overview
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/financial-advisor/'
                                id='nmx-nav-link-primary-sub-financial-advisor'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-who-we-are'>
                                Working With an Advisor
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/financial-information/'
                                id='nmx-nav-link-primary-sub-our-financial-strength'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-who-we-are'>
                                Our Financial Strength
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/who-we-are/sustainability-social-impact/'
                                id='nmx-nav-link-primary-sub-sustainability-social-impact-landing'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-nav-link-primary-sub--last nmx-subnav-item-who-we-are'>
                                Sustainability and Social Impact
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          role='none'
                          className='nmx-nav-link-container nmx-nav-link-primary-container'>
                          <a
                            role='menuitem'
                            aria-haspopup='true'
                            className='nmx-nav-link nmx-nav-link-primary'
                            href='/financial-planning/'
                            id='nmx-nav-link-primary-financial-planning'
                            data-subnav-active='false'
                            aria-expanded='false'>
                            Financial Planning
                          </a>
                          <ul
                            role='menu'
                            className='reduced nmx-subnav nmx-subnav-financial-planning'
                            aria-label='Planning Services Submenu'>
                            <li
                              role='none'
                              className='nmx-nav-link-container nmx-nav-link-mobile-back-container'>
                              <Button
                                id='button-sub-nav-back-to-main-menu'
                                role='menuitem'
                                variant='tertiary'
                                tabIndex='-1'
                                className='nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub--first nmx-nav-link-mobile-back'>
                                Back to main menu
                                <span className='nmx-nav-mobile-subnav-bg' />
                              </Button>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-mobile-subnav-overview'
                                href={`${basePath}/financial-planning/`}>
                                Financial Planning Overview
                              </a>
                            </li>
                            <li
                              role='none'
                              className='nmx-nav-link-container nmx-nav-link-primary-sub-container'>
                              <a
                                role='menuitem'
                                href='/retirement-planning/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-financial-planning has-subnav-level2'
                                id='nmx-nav-link-primary-sub-retirement-planning'
                                aria-haspopup='true'
                                aria-expanded='false'>
                                Retirement Planning
                              </a>
                              <ul
                                role='menu'
                                className='reduced nmx-subnav-level2 nmx-subnav-level2-retirement-planning'
                                aria-label='Retirement Planning Submenu'>
                                <li
                                  role='none'
                                  className='nmx-nav-link-container nmx-nav-link-mobile-level2-back-container'>
                                  <Button
                                    id='button-sub-nav-back-to-financial-planning'
                                    variant='tertiary'
                                    role='menuitem'
                                    tabIndex='-1'
                                    className='nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub-level2--first nmx-nav-link-mobile-level2-back'>
                                    Back to Planning Services
                                    <span className='nmx-nav-mobile-subnav-bg' />
                                  </Button>
                                </li>
                                <li
                                  role='none'
                                  itemProp='name'
                                  className='nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container'>
                                  <a
                                    role='menuitem'
                                    itemProp='url'
                                    tabIndex='-1'
                                    className='nmx-nav-link nmx-nav-link-mobile-subnav-overview'
                                    href={`${basePath}/retirement-planning/`}>
                                    Retirement Planning Overview
                                  </a>
                                </li>
                                <li
                                  role='none'
                                  itemProp='name'
                                  className='nmx-nav-link-container'>
                                  <a
                                    role='menuitem'
                                    itemProp='url'
                                    href='/retirement-calculator/'
                                    id='nmx-nav-link-primary-sub-level2-retirement-calculator'
                                    tabIndex='-1'
                                    className='nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-nav-link-primary-sub-level2--last nmx-subnav-level2-item-retirement-planning has-icon'>
                                    Retirement Calculator
                                    <BeachChairWithBorderIcon />
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/college-savings-plans/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-financial-planning'
                                id='nmx-nav-link-primary-sub-college-savings-plans'>
                                College Savings Plans
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/private-wealth-management/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-financial-planning'
                                id='nmx-nav-link-primary-sub-private-wealth-management'>
                                Private Wealth Management
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/financial-planning/estate-planning/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-financial-planning'
                                id='nmx-nav-link-primary-sub-estate-planning'>
                                Estate Planning
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/long-term-care-planning/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-financial-planning'
                                id='nmx-nav-link-primary-sub-financial-planning-long-term-care'>
                                Long&#45;Term Care
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/financial-planning/business-services/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-nav-link-primary-sub--last nmx-subnav-item-financial-planning'
                                id='nmx-nav-link-primary-sub-business-services'>
                                Business Services
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          role='none'
                          className='nmx-nav-link-container nmx-nav-link-primary-container'>
                          <a
                            role='menuitem'
                            aria-haspopup='true'
                            className='nmx-nav-link nmx-nav-link-primary'
                            href='/insurance/'
                            id='nmx-nav-link-primary-insurance'
                            data-subnav-active='false'
                            aria-expanded='false'>
                            Insurance
                          </a>
                          <ul
                            role='menu'
                            className='reduced nmx-subnav nmx-subnav-insurance'
                            aria-label='Insurance Submenu'>
                            <li
                              role='none'
                              className='nmx-nav-link-container nmx-nav-link-mobile-back-container'>
                              <Button
                                id='button-sub-nav-insurance-back-to-main-menu'
                                role='menuitem'
                                variant='tertiary'
                                tabIndex='-1'
                                className='nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub--first nmx-nav-link-mobile-back'>
                                Back to main menu
                                <span className='nmx-nav-mobile-subnav-bg' />
                              </Button>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-mobile-subnav-overview'
                                href={`${basePath}/insurance/`}>
                                Insurance Overview
                              </a>
                            </li>
                            <li
                              role='none'
                              className='nmx-nav-link-container nmx-nav-link-primary-sub-container'>
                              <a
                                role='menuitem'
                                href='/life-insurance/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-insurance has-subnav-level2'
                                id='nmx-nav-link-primary-sub-life-insurance'
                                aria-haspopup='true'
                                aria-expanded='false'>
                                Life Insurance
                              </a>
                              <ul
                                role='menu'
                                className='reduced nmx-subnav-level2 nmx-subnav-level2-life-insurance'
                                aria-label='Life Insurance Submenu'>
                                <li
                                  role='none'
                                  className='nmx-nav-link-container nmx-nav-link-mobile-level2-back-container'>
                                  <Button
                                    id='button-sub-nav-back-to-insurance'
                                    variant='tertiary'
                                    role='menuitem'
                                    tabIndex='-1'
                                    className='nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub-level2--first nmx-nav-link-mobile-level2-back'>
                                    Back to Insurance
                                    <span className='nmx-nav-mobile-subnav-bg' />
                                  </Button>
                                </li>
                                <li
                                  role='none'
                                  itemProp='name'
                                  className='nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container'>
                                  <a
                                    role='menuitem'
                                    itemProp='url'
                                    tabIndex='-1'
                                    className='nmx-nav-link nmx-nav-link-mobile-subnav-overview'
                                    href='/life-insurance/'>
                                    Life Insurance Overview
                                  </a>
                                </li>
                                <li
                                  role='none'
                                  itemProp='name'
                                  className='nmx-nav-link-container'>
                                  <a
                                    role='menuitem'
                                    itemProp='url'
                                    href='/life-insurance/whole-life-insurance/'
                                    id='nmx-nav-link-primary-sub-level2-whole-life-insurance'
                                    tabIndex='-1'
                                    className='nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-subnav-level2-item-life-insurance'>
                                    Whole Life Insurance
                                  </a>
                                </li>
                                <li
                                  role='none'
                                  itemProp='name'
                                  className='nmx-nav-link-container'>
                                  <a
                                    role='menuitem'
                                    itemProp='url'
                                    href='/life-insurance/universal-life-insurance/'
                                    id='nmx-nav-link-primary-sub-level2-universal-life-insurance'
                                    tabIndex='-1'
                                    className='nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-subnav-level2-item-life-insurance'>
                                    Universal Life Insurance
                                  </a>
                                </li>
                                <li
                                  role='none'
                                  itemProp='name'
                                  className='nmx-nav-link-container'>
                                  <a
                                    role='menuitem'
                                    itemProp='url'
                                    href='/life-insurance/variable-universal-life-insurance-vul/'
                                    id='nmx-nav-link-primary-sub-level2-variable-universal-life-insurance'
                                    tabIndex='-1'
                                    className='nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-subnav-level2-item-life-insurance'>
                                    Variable Universal Life Insurance
                                  </a>
                                </li>
                                <li
                                  role='none'
                                  itemProp='name'
                                  className='nmx-nav-link-container'>
                                  <a
                                    role='menuitem'
                                    itemProp='url'
                                    href='/life-insurance/term-life-insurance/'
                                    id='nmx-nav-link-primary-sub-level2-term-life-insurance'
                                    tabIndex='-1'
                                    className='nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-subnav-level2-item-life-insurance'>
                                    Term Life Insurance
                                  </a>
                                </li>
                                <li
                                  role='none'
                                  itemProp='name'
                                  className='nmx-nav-link-container'>
                                  <a
                                    role='menuitem'
                                    itemProp='url'
                                    href='/life-insurance/life-insurance-calculator/'
                                    id='nmx-nav-link-primary-sub-level2-life-insurance-calculator'
                                    tabIndex='-1'
                                    className='nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-nav-link-primary-sub-level2--last nmx-subnav-level2-item-life-insurance has-icon'>
                                    Life Insurance Calculator
                                    <ShieldWithHeartBorderIcon />
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li
                              role='none'
                              className='nmx-nav-link-container nmx-nav-link-primary-sub-container'>
                              <a
                                role='menuitem'
                                href='/disability-insurance/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-insurance has-subnav-level2'
                                id='nmx-nav-link-primary-sub-disability-insurance'
                                aria-haspopup='true'
                                aria-expanded='false'>
                                Disability Insurance
                              </a>
                              <ul
                                role='menu'
                                className='reduced nmx-subnav-level2 nmx-subnav-level2-disability-insurance'
                                aria-label='Disability Insurance Submenu'>
                                <li
                                  role='none'
                                  className='nmx-nav-link-container nmx-nav-link-mobile-level2-back-container'>
                                  <Button
                                    id='button-sub-nav-back-to-disability-insurance'
                                    variant='tertiary'
                                    role='menuitem'
                                    tabIndex='-1'
                                    className='nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub-level2--first nmx-nav-link-mobile-level2-back'>
                                    Back to Insurance
                                    <span className='nmx-nav-mobile-subnav-bg' />
                                  </Button>
                                </li>
                                <li
                                  role='none'
                                  itemProp='name'
                                  className='nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container'>
                                  <a
                                    role='menuitem'
                                    itemProp='url'
                                    tabIndex='-1'
                                    className='nmx-nav-link nmx-nav-link-mobile-subnav-overview'
                                    href={`${basePath}/disability-insurance/`}>
                                    Disability Insurance Overview
                                  </a>
                                </li>
                                <li
                                  role='none'
                                  itemProp='name'
                                  className='nmx-nav-link-container'>
                                  <a
                                    role='menuitem'
                                    itemProp='url'
                                    href='/disability-insurance/'
                                    id='nmx-nav-link-primary-sub-level2-for-individuals'
                                    tabIndex='-1'
                                    className='nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-subnav-level2-item-disability-insurance'>
                                    <AssistiveText>
                                      Disability Insurance
                                    </AssistiveText>
                                    For Individuals
                                  </a>
                                </li>
                                <li
                                  role='none'
                                  itemProp='name'
                                  className='nmx-nav-link-container'>
                                  <a
                                    role='menuitem'
                                    itemProp='url'
                                    href='/disability-insurance/physicians-and-dentists-disability-insurance/'
                                    id='nmx-nav-link-primary-sub-level2-for-doctors-and-dentists'
                                    tabIndex='-1'
                                    className='nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-subnav-level2-item-disability-insurance'>
                                    <AssistiveText>
                                      Disability Insurance
                                    </AssistiveText>
                                    For Doctors and Dentists
                                  </a>
                                </li>
                                <li
                                  role='none'
                                  itemProp='name'
                                  className='nmx-nav-link-container'>
                                  <a
                                    role='menuitem'
                                    itemProp='url'
                                    href='/disability-insurance/disability-insurance-calculator/'
                                    id='nmx-nav-link-primary-sub-level2-disability-insurance-calculator'
                                    tabIndex='-1'
                                    className='nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-nav-link-primary-sub-level2--last nmx-subnav-level2-item-disability-insurance has-icon'>
                                    Disability Insurance Calculator
                                    <MoneyParachuteWithBorderIcon />
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/long-term-care-planning/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-insurance'
                                id='nmx-nav-link-primary-sub-insurance-long-term-care'>
                                Long&#45;Term Care
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/annuities/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-nav-link-primary-sub--last nmx-subnav-item-insurance'
                                id='nmx-nav-link-primary-sub-income-annuities'>
                                Income Annuities
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          role='none'
                          className='nmx-nav-link-container nmx-nav-link-primary-container'>
                          <a
                            role='menuitem'
                            aria-haspopup='true'
                            className='nmx-nav-link nmx-nav-link-primary'
                            href='/investments/'
                            id='nmx-nav-link-primary-investments'
                            data-subnav-active='false'
                            aria-expanded='false'>
                            Investments
                          </a>
                          <ul
                            role='menu'
                            className='reduced nmx-subnav nmx-subnav-investments'
                            aria-label='Investments Submenu'>
                            <li
                              role='none'
                              className='nmx-nav-link-container nmx-nav-link-mobile-back-container'>
                              <Button
                                id='button-sub-nav-back-to-investments'
                                role='menuitem'
                                variant='tertiary'
                                tabIndex='-1'
                                className='nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub--first nmx-nav-link-mobile-back'>
                                Back to main menu
                                <span className='nmx-nav-mobile-subnav-bg' />
                              </Button>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-mobile-subnav-overview'
                                href={`${basePath}/investments/`}>
                                Investments Overview
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/investments/brokerage-accounts-services/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-investments'
                                id='nmx-nav-link-primary-sub-brokerage-accounts-services'>
                                Brokerage Accounts &#38; Services
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/private-wealth-management/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-investments'
                                id='nmx-nav-link-primary-sub-investments-private-wealth-management'>
                                Private Wealth Management
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/investments/investment-advisory-services/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-investments'
                                id='nmx-nav-link-primary-sub-investment-advisory-services'>
                                Investment Advisory Services
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/investments/fixed-annuity-vs-variable-annuity/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-investments'
                                id='nmx-nav-link-primary-sub-fixed-annuity-vs-variable-annuity'>
                                Fixed &#38; Variable Annuities
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/life-and-money/market-commentary/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub--last nmx-nav-link-primary-sub nmx-nav-link-primary-sub--last nmx-subnav-item-investments'
                                id='nmx-nav-link-primary-sub-investments-market-commentary'>
                                Market Commentary
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          role='none'
                          className='nmx-nav-link-container nmx-nav-link-primary-container'>
                          <a
                            role='menuitem'
                            aria-haspopup='true'
                            className='nmx-nav-link nmx-nav-link-primary'
                            href='/life-and-money/'
                            id='nmx-nav-link-primary-life-and-money'
                            data-subnav-active='false'
                            aria-expanded='false'>
                            Life &#38; Money
                          </a>
                          <ul
                            role='menu'
                            className='reduced nmx-subnav nmx-subnav-life-and-money'
                            aria-label='Life &#38; Money Submenu'>
                            <li
                              role='none'
                              className='nmx-nav-link-container nmx-nav-link-mobile-back-container'>
                              <Button
                                id='button-sub-nav-back-to-life-and-money'
                                role='menuitem'
                                variant='tertiary'
                                tabIndex='-1'
                                className='nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub--first nmx-nav-link-mobile-back'>
                                Back to main menu
                                <span className='nmx-nav-mobile-subnav-bg' />
                              </Button>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-mobile-subnav-overview'
                                href={`${basePath}/life-and-money/`}>
                                Life &#38; Money Overview
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/life-and-money/financial-planning/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money'
                                id='nmx-nav-link-primary-sub-financial-planning'>
                                <AssistiveText>
                                  Educational Resources About
                                </AssistiveText>
                                Financial Planning
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/life-and-money/investing/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money'
                                id='nmx-nav-link-primary-sub-investing'>
                                <AssistiveText>
                                  Educational Resources About
                                </AssistiveText>
                                Investing
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/life-and-money/insurance/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money'
                                id='nmx-nav-link-primary-sub-insurance'>
                                <AssistiveText>
                                  Educational Resources About
                                </AssistiveText>
                                Insurance
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/life-and-money/everyday-money/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money'
                                id='nmx-nav-link-primary-sub-everyday-money'>
                                <AssistiveText>
                                  Educational Resources About
                                </AssistiveText>
                                Everyday Money
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/life-and-money/family-and-work/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money'
                                id='nmx-nav-link-primary-sub-family-and-work'>
                                <AssistiveText>
                                  Educational Resources About
                                </AssistiveText>
                                Family &amp; Work
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/life-and-money/market-commentary/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money'
                                id='nmx-nav-link-primary-sub-life-and-money-market-commentary'>
                                Market Commentary
                              </a>
                            </li>
                            <li
                              role='none'
                              itemProp='name'
                              className='nmx-nav-link-container'>
                              <a
                                role='menuitem'
                                itemProp='url'
                                href='/podcast/'
                                tabIndex='-1'
                                className='nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money nmx-nav-link-primary-sub--last'
                                id='nmx-nav-link-primary-sub-life-and-money-podcast'>
                                Podcast
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </NavPrimaryStyled>
                  )}
                </ColNavContainerStyled>
              </Row>
              {useFwmNav && (
                <>
                  <Row
                    className='nmx-row nmx-row--align-right'
                    style={{ height: '0' }}>
                    <ColNavUtilityFwmContainerStyled>
                      <FwmNavItemContainer className='reduced'>
                        <FwmNavItem>
                          <FwmNavLink
                            {...CLAIMS_LINK_BUTTON_DEFAULT_PROPS}
                            style={{
                              color: '#4a4a4a',
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            {!smallDown && (
                              <PaperIcon
                                style={{ marginRight: '3px' }}
                                fill='#4a4a4a'
                              />
                            )}
                            <span>CLAIMS</span>
                          </FwmNavLink>
                        </FwmNavItem>
                        <FwmNavItem>
                          {!smallDown && (
                            <CircleWithPerson
                              height={20}
                              width={20} />
                          )}
                          <UserLoginButton
                            config={config}
                            variant='fwm' />
                        </FwmNavItem>
                      </FwmNavItemContainer>
                    </ColNavUtilityFwmContainerStyled>
                  </Row>
                </>
              )}
              {/* TODO: bring in <UtilityNavigation /> component - this chunk of code is still using hard-coded */}
              {!hideHeader && (
                <>
                  <Row className='nmx-row nmx-row--align-right'>
                    <ColNavUtilityContainerStyled
                      className='nmx-col nmx-nav-utility-container'
                      id='nav-utility-container'>
                      <NavUtilityStyled
                        aria-label='Utility Navigation'
                        className='nmx-nav-utility'
                        id='nmx-nav-utility'
                        itemScope
                        itemType='https://schema.org/SiteNavigationElement'
                      >
                        <AssistiveText component='h6'>
                          Utility Navigation
                        </AssistiveText>
                        <ul
                          role='menu'
                          className='reduced'>
                          <LiLoginButtonContainer
                            role='none'
                            itemProp='name'
                            className='nmx-nav-link-utility-container'
                            id='nmx-nav-link-login-button-container'>
                            <div className='nmx-login-button'>
                              <UserLoginButton config={config} />
                            </div>
                          </LiLoginButtonContainer>
                          <li
                            role='none'
                            itemProp='name'
                            className='nmx-nav-link-utility-container nmx-nav-link-utility-container-mobile-button'>
                            {isMobile ? (
                              <Link
                                {...FAFA_LINK_BUTTON_DEFAULT_PROPS}
                                themeType='lightTheme'
                                variant='buttonLinkPrimary'>
                                Find a Financial Advisor
                              </Link>
                            ) : (
                              <a {...CLAIMS_LINK_DEFAULT_PROPS}>Claims</a>
                            )}
                          </li>
                          <li
                            role='none'
                            itemProp='name'
                            className='nmx-nav-link-utility-container nmx-nav-link-utility-container-mobile-button'
                            id='nmx-nav-link-fafa-link-container'>
                            {isMobile ? (
                              <Link
                                {...CLAIMS_LINK_BUTTON_DEFAULT_PROPS}
                                variant='buttonLinkSecondary'>
                                Claims
                              </Link>
                            ) : (
                              <Link
                                {...FAFA_LINK_BUTTON_DEFAULT_PROPS}
                                themeType='darkTheme'
                                variant='buttonLinkPrimary'>
                                Find a Financial Advisor
                              </Link>
                            )}
                          </li>
                          <LiNavLinkSearchContainerStyled
                            role='none'
                            itemProp='name'
                            className='nmx-nav-link-utility-container'
                            id='nmx-nav-link-search-container'>
                            <ButtonNavLinkUtilitySearchStyled
                              role='menuitem'
                              className='nmx-button nmx-nav-link nmx-nav-link-utility'
                              id='nmx-nav-link-utility-search'>
                              Search
                              <MagnifyingGlass className='nmx-icon-search' />
                            </ButtonNavLinkUtilitySearchStyled>
                          </LiNavLinkSearchContainerStyled>
                        </ul>
                      </NavUtilityStyled>
                    </ColNavUtilityContainerStyled>
                  </Row>
                  <Row className='nmx-row'>
                    <Col className='nmx-col'>
                      <div id='nmx-search-console'>
                        <Search
                          id='nmx-search-console'
                          config={config}
                          pageLoadWindowHeight={theme.pageLoadWindowHeight}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </ContainerMobileNavStyled>
          </PrimaryHeaderContainerStyled>
          {breadcrumbsNavigationConfig && (
            <BreadcrumbsNavigation
              config={breadcrumbsNavigationConfig}
              showInformationalBanner={showInformationalBanner}
            />
          )}
        </HeaderStyled>
      </>
    );
  },
);

HeaderComponent.propTypes = {
  /** optional breadcrumbsNavigationConfig determines if breadcrumbs are displayed */
  breadcrumbsNavigationConfig: PropTypes.array,
  /** Config vars used in header */
  config: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    nmxTemplateVersion: PropTypes.string.isRequired,
    public: PropTypes.shape({
      googleAnalyticsTrackingId: PropTypes.string,
      googlePlacesApiKey: PropTypes.string,
    }).isRequired,
    services: PropTypes.shape({
      loginPageUrl: PropTypes.string.isRequired,
      loginPageUrlBase: PropTypes.string.isRequired,
      userLogoutUrls: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  /** config object coming from nmx-brains */
  dynamicConfig: PropTypes.object,
  /** boolean headerIsOverlayed (coupled with hideHeader will show white logo overlayed on top of dark body content), temporarily removed when StickyNav was introduced */
  headerIsOverlayed: PropTypes.bool,
  /** boolean headerLogoEyebrow (will enable logo-only block header above body content, small size by default (roughly 200 x 50)) */
  headerLogoEyebrow: PropTypes.bool,
  /** boolean headerLogoEyebrowLarge (will enable large header logo (roughly 500 x 100))  */
  headerLogoEyebrowLarge: PropTypes.bool,
  /** boolean headerWithoutBorder (will remove bottom header border logo-only or full nav) */
  headerWithoutBorder: PropTypes.bool,
  /** boolean hideHeader (will enable logo-only header and remove any primary navigation including login) */
  hideHeader: PropTypes.bool,
  /** coupled with staticInformationalBannerConfig, boolean showInformationalBanner  */
  showInformationalBanner: PropTypes.bool,
  /** boolean allows skipNavAside to appear in skipNav menu */
  skipNavAside: PropTypes.bool,
  /** boolean allows skipNavFooter to appear in skipNav menu */
  skipNavFooter: PropTypes.bool,
  /** show useFwmNav */
  useFwmNav: PropTypes.bool,
};

HeaderComponent.defaultProps = {
  headerIsOverlayed: false,
  headerLogoEyebrow: false,
  headerLogoEyebrowLarge: false,
  headerWithoutBorder: false,
  hideHeader: false,
  showInformationalBanner: false,
  skipNavAside: false,
  skipNavFooter: false,
};

export default HeaderComponent;
